import React, {useState} from "react";
import { slide as MenuMobile } from "react-burger-menu";
import { Link } from 'gatsby'

export default props => {
    const [isOpenMenu, setMenu] = useState(false);
  return (
    // Pass on our props
    <MenuMobile isOpen={isOpenMenu} onClose={() => setMenu(false)} onOpen={() => setMenu(true)} width={ '100%' } {...props}>
      <Link onClick={() => setMenu(false)} className="menu-item" to="/">Accueil</Link>
      <Link onClick={() => setMenu(false)} className="menu-item" to="/informatique">Informatique</Link>
      <Link onClick={() => setMenu(false)} className="menu-item" to="/mobiles">Mobiles</Link>
      <Link onClick={() => setMenu(false)} className="menu-item" to="/tarifs">Nos tarifs</Link>
      <a onClick={() => setMenu(false)} className="menu-item" href="https://boutique.latelierinfo.com/">Notre boutique</a>
      <Link onClick={() => setMenu(false)} className="menu-item" to="/contact" >Contactez-nous</Link>
    </MenuMobile>
  );
};