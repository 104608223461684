import React from 'react'
import { Link } from 'gatsby'

import Logo from '../images/svg/logo-2.svg'
import Facebook from '../images/svg/facebook.svg'

import BgFooterImg from '../images/svg/bg-footer.svg'
import FooterCircleImg from '../images/svg/footer-circle.svg'

import styles from './footer.module.scss'

export default () => (
	<footer className={`${styles.footer} container`}>
		<FooterCircleImg className={styles.footerCircle}/>
		<BgFooterImg className={styles.footerImg}/>
		<div>
			<a aria-label="Retour accueil" className={styles.footerLogo} href="/"><Logo/></a>
		</div>
		<ul className={styles.footerList}><h3>Liens</h3>
			<li className={styles.footerItem}>
				<Link to="/">Accueil</Link>
			</li>
			<li className={styles.footerItem}>
				<Link to="/informatique">Informatique</Link>
			</li>
			<li className={styles.footerItem}>
				<Link to="/mobiles">Mobiles</Link>
			</li>
            <li className={styles.footerItem}>
				<Link to="/politique">Politique de confidentialité</Link>
			</li>
			{/*<li className={styles.footerItem}>
				<Link to="/tarifs/">Nos tarifs</Link>
			</li>*/}
			{/*<li className={styles.footerItem}>
				<Link to="/a-propos/">À propos</Link>
			</li>*/}
			{/*<li className={styles.footerItem}>
				<Link to="/formations/">Formations </Link>
			</li>*/}
		</ul>
		<div>
			<h3>CONTACT</h3>
			<p>
				1520 Boulevard Industriel,<br/>
				Chambly, QC, J3L 6Z7<br/>
				<a className="link link--primary" href="tel:4506585005">450-658-5005</a><br/>
				contact@latelierinfo.com<br/>
			</p>
			<a className={styles.footerIconsocial} aria-label="Link to facebook page" href="https://www.facebook.com/latelierinfo01"><Facebook /></a>
		</div>
		<ul className={styles.footerList}><h3>Horaires estivales</h3>
			<li className={styles.footerItem}>
				Lundi Fermé
			</li>
			<li className={styles.footerItem}>
				Mardi 9h à 17h
			</li>
			<li className={styles.footerItem}>
				Mercredi 9h à 17h
			</li>
			<li className={styles.footerItem}>
				Jeudi 9h à 19h
			</li>
			<li className={styles.footerItem}>
				Vendredi 9h à 19h
			</li>
			<li className={styles.footerItem}>
				Samedi 10h à 17h
			</li>
			<li className={styles.footerItem}>
				Dimanche Fermé
			</li>
		</ul>
  </footer>
  /**/
)
