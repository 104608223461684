import React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/svg/logo-horizontal.svg'
import LogoPage from '../images/svg/logo-2.svg'
import styles from './navigation.module.scss'



export default ({layoutpage}) => {

	let isHome = false;
	if(layoutpage === '/'){
		isHome = true
	}

	return(
	<nav role="navigation" className={`${styles.navigation} container`}>
		<a aria-label="Retour accueil" className={styles.navigationLogo} href="/">{isHome ? <Logo/> : <LogoPage width="266"/>}</a>
		<ul className={styles.navigationList}>
			<li className={styles.navigationItem}>
				<Link aria-label="allez accueil" to="/">Accueil</Link>
			</li>
			<li className={styles.navigationItem}>
				<Link aria-label="allez service" to="/informatique">Informatique</Link>
			</li>
			<li className={styles.navigationItem}>
				<Link aria-label="allez a propos" to="/mobiles/">Mobiles</Link>
			</li>
			<li className={styles.navigationItem}>
				<Link aria-label="allez tarifs" to="/tarifs/">Nos tarifs</Link>
			</li>
            <li className={styles.navigationItem}>
				<a aria-label="allez boutique" href="https://boutique.latelierinfo.com/">Notre boutique</a>
			</li>
			<li className={styles.navigationItem_btn}>
				<Link aria-label="allez contact" className="btn btn--primary btn--black" to="/contact/">Contactez-nous</Link>
			</li>
		</ul>
	</nav>
	)
}